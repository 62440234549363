import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as PlayIcon } from "../../assets/Icons/play.svg";
import { appInfo, fetchEventDetails, fetchEventSubscriptionDetails } from "../../network/service";
import { updateWatchlistData } from "../../network/service";
import { ToastContainer, toast } from "react-toastify";
import { fetchWatchlistShows } from "../../Screens/MyList/service";
import { getMyListData } from "../../Redux/MyList/MyListSlice";
import { ReactComponent as PremiumIcon } from "../../assets/Icons/premium.svg";
import { ReactComponent as AddListIcon } from "../../assets/Icons/addToWatchlist.svg";
import { ReactComponent as DownArrow } from "../../assets/Icons/pageDownArrow.svg";
import { ReactComponent as RemoveFromWatchListIcon } from "../../assets/Icons/removeFromWatchlist.svg";
import { getMoreInfo } from "../../Redux/MoreInfo/moreInfoSlice";
import { imageUrlCheck, secondsToMinutes } from "../../utils/utils";
import { getMoreInfoOther } from "../../Redux/MoreInfo/moreInfoOtherSlice";
import { getPodcastModal } from "../../Redux/PodcastModal/podcastModalSlice";
import { getEventMoreInfo } from "../../Redux/MoreInfo/eventMoreInfoSlice";
import ImageWithFallback from "../ImageWithFallback/ImageWithFallback";
import Translate from "../MultiLanguage/Translate";

const ShowCard = ({ data, metaData, imageUrl, type,cardHover,modalPageNavigation,showCount,categoryData,categoryName,thumbnailOrientation }) => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const user = useSelector((state) => state?.user?.value);
  const isRTL = useSelector((state) => state?.rtl?.isRTL);
  const userSubscriptionData = useSelector(
    (state) => state?.userSubscription?.value
  );
  const [watchlistStatus, setWatchlistStatus] = useState();
  // const [thumbnailOrientation, setThumbnailOrientation] = useState("");
  const [imagURL,setImageURL] = useState(projectInfo?.projectConfig?.config?.PLACEHOLDER_IMAGE)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };

  useEffect(() => {
    if (data?.watchlist_flag === 1) {
      setWatchlistStatus("added");
    } else {
      setWatchlistStatus("removed");
    }
  }, [data]);

  // useEffect(() => {
  //   if (
  //     projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION === "PORTRAIT"
  //   ) {
  //     setThumbnailOrientation("PORTRAIT");
  //   } else {
  //     setThumbnailOrientation("LANDSCAPE");
  //   }
  // }, [projectInfo]);
  
  const fetchWatchlist = async () => {
    const response = await fetchWatchlistShows(appInfo);
    if (response?.status === 200) {
      dispatch(
        getMyListData({
          myList: response?.data?.data,
        })
      );
    }
  };

  const updateWatchlist = async (showId, flag) => {
    try {
      const response = await updateWatchlistData(appInfo, showId, flag);

      if (response?.status === 200) {
        fetchWatchlist();
        if (flag === 1) {
          setWatchlistStatus("added");
        } else {
          setWatchlistStatus("removed");
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  const addToWatchlist = (e) => {
    e.stopPropagation();
    if (user) {
      updateWatchlist(data?.show_id, 1);
    } else {
      navigate("/login");
    }
  };
  const removeFromWatchlist = (e) => {
    e.stopPropagation();
    updateWatchlist(data?.show_id, 0);
  };



  const showClickHandler = (id, showId) => {
    // document.body.style.overflow='hidden'
    // document.body.className="scrollLock"
    if (data?.type === "LIVE_EVENT") {
      if (modalPageNavigation === false) {
        dispatch(
          getEventMoreInfo({
            eventMoreInfo: {
              isModal: true,
              eventId: data?.vanity_url ? data?.vanity_url : data?.event_id
            }
          })
        )
      } else {
        navigate(`/event/${id}`, { state: { showId } })

      }
    } else if (data?.type === "PODCAST") {
      let podcastData = {
        isOpen: true,
        data
      }
      dispatch(
        getPodcastModal({
          podcastModal: podcastData
        })
      )
    } else if (data?.type === "NEWS") {
      navigate(`/news-info/${id}`)
    }
    else if(data?.type === "CONTINUE_WATCHING" || type === "CONTINUE_WATCHING" || categoryName === "Continue Watching"){    
      navigate(`/videos/${data?.video_vanity_url}`, {
        state: { showId: data?.show_id,type:data?.type, videoDetails:data ,continue:true },
      })
    }
    else if (data?.type === "UPCOMING_EVENT") {
      if (modalPageNavigation === false) {
        dispatch(
          getEventMoreInfo({
            eventMoreInfo: {
              isModal: true,
              eventId: data?.vanity_url ? data?.vanity_url : data?.event_id
            }
          })
        )
      } else {
        navigate(`/event/${id}`, { state: { showId } })

      }
    }
    else if(data?.type === "VIDEO"){
      navigate(`/videos/${data?.vanity_url}`, {
        state: { videoDetails:data },
      })
    }
    else if(data?.type === "SHOW"){
      navigate(`/show-details/${data?.vanity_url}`, {
        state: { showId: data?.show_id,type:data?.type },
      })
    }
    else if(data?.type === "SHORTS"){
      navigate(`/shorts/${data?.vanity_url?data?.vanity_url:data?.event_id}`
      //   , {
      //   state: { videoDetails:data },
      // }
    )
    }
    else if(data?.type === "FASTCHANNEL"){
      navigate(`/live-channels`, { 
      })
    }
    else if(data?.type === "EVENT"){
      navigate(`/event/${data?.vanity_url?data?.vanity_url:data?.event_id}`)
    }
    else if( type === "Episode"){
      navigate(`/videos/${data?.vanity_url}`, {
        state: { videoDetails:data },
      })
    }
    else if(type!=='episodes'){
      navigate(`/show-details/${data?.vanity_url}`, {
        state: { showId: data?.show_id,type:data?.type },
      })
    }
    else if(data?.type === "PODCASTS"){
      let podcastData = {
        isOpen:true,
        data
      }
      dispatch(
        getPodcastModal({
          podcastModal:podcastData
        })
      )
    }
    else {

      if (modalPageNavigation === false) {
        dispatch(
          getMoreInfoOther({
            moreInfoOther: {
              isModal: true,
              showId: id
            }
          })
        )
      } else {
        navigate(`/show-details/${id}`, { state: { showId } })
        dispatch(
          getMoreInfo({
            moreInfo: true
          })
        )
      }
    }
  }

  const subscriptionCheck = (userSub, videoSub) => {
    const hasMatch = userSub?.some((user) =>
      videoSub?.some((video) => user.sub_id === video.subscription_id)
    );
    return hasMatch;
  };

  const getEventSubScriptionDetails = async () => {
    const response = await fetchEventSubscriptionDetails(appInfo, data?.event_id);
    if (response?.status === 200) {
      return response?.data?.data
    }
  }

  const replaceImage=(error)=>{
    error.target.src= thumbnailOrientation==='PORTRAIT'? projectInfo?.projectConfig?.config?.PLACEHOLDER_IMAGE_PORTRAIT : projectInfo?.projectConfig?.config?.PLACEHOLDER_IMAGE
  }

  const afterImageLoad = (e) => {
    let image;
    if (imageUrlCheck(thumbnailOrientation === 'PORTRAIT' ? data?.logo || data?.thumbnail : data?.logo_thumb || data?.thumbnail_350_200) === true) {
      if (thumbnailOrientation === "PORTRAIT") {
        image = data?.logo || data?.thumbnail
      } else {
        image = data?.logo_thumb || data?.thumbnail_350_200
      }
    } else {
      if (thumbnailOrientation === "PORTRAIT") {
        image = process.env.REACT_APP_IMAGE_URL + (data?.logo || data?.thumbnail)
      } else {
        image = process.env.REACT_APP_IMAGE_URL + (data?.logo_thumb || data?.thumbnail_350_200)
      }
    }
    setImageURL(image)
  }

  return (
    <div
      className={`showCardContainer ${cardHover ? 'cardHover' : ''} ${isRTL ? 'rtl' : ''}`}
      title={data?.show_name || data?.video_title}
    >
      <div className="showMoreOverlay"
        onClick={() =>
          navigate(`/category/${categoryData?.key}?career=${categoryData?.key}`, {
            state: { careers: categoryData?.key, type: categoryData?.type },
          })
        }
      >
        <h2><Translate textKey={'show_all'} /> ( {showCount} )</h2>
      </div>

      {/* <ToastContainer /> */}
      {/* <div className="premiumStatus">
        {data?.is_free_video === false ? (
          <div className="premium">
            <PremiumIcon />
          </div>
        ) : data?.is_free_video === true ? (
          <div className="free">
            <img
              src={require("../../assets/Images/free_icon.png")}
              alt="FreeIcon"
            />
          </div>
        ) : null}
      </div> */}

      {/* <div className="imageContainer">
        {imageUrl ? (
          <img
            src={
              thumbnailOrientation === "PORTRAIT"
                ? `${process.env.REACT_APP_IMAGE_URL}${
                    data?.logo || data?.thumbnail
                  }`
                : `${process.env.REACT_APP_IMAGE_URL}${
                    data?.logo_thumb || data?.thumbnail_350_200
                  }`
            }
            alt="ShowImage"
          />
        ) : (
          <img
            src={
              thumbnailOrientation === "PORTRAIT"
                ? `${data?.logo || data?.thumbnail}`
                : `${data?.logo_thumb || data?.thumbnail_350_200}`
            }
            alt="ShowImage"
          />
        )}
        {type === "CONTINUE_WATCHING" && (
          <div className="continueWatchingBar">
            <div
              className="line"
              style={{
                width:
                  data?.watched_percentage <= 1
                    ? 1 + "%"
                    : data?.watched_percentage + "%",
              }}
            ></div>
          </div>
        )}

        
      </div> */}

      <div className="square" onClick={() => showClickHandler(data?.vanity_url ? data?.vanity_url : data?.show_id ? data?.show_id : data?.event_id, data?.show_id)}>
        <div className="imageContainer">
          <div className="premiumStatus">
            {data?.is_free_video === true  ? (
              <div className="free">
                <img
                  src={require("../../assets/Images/free_icon.png")}
                  alt="FreeIcon"
                />
              </div>
            ) : null}
          </div>
          {/* {imageUrlCheck(thumbnailOrientation==='PORTRAIT'?data?.logo || data?.thumbnail:data?.logo_thumb || data?.thumbnail_350_200)===false ? (
            
            // <ImageWithFallback
            // projectInfo={projectInfo} 
            // src={
            //   thumbnailOrientation === "PORTRAIT"
            //     ? `${process.env.REACT_APP_IMAGE_URL}${
            //         data?.logo || data?.thumbnail
            //       }`
            //     : `${process.env.REACT_APP_IMAGE_URL}${
            //         data?.logo_thumb || data?.thumbnail_350_200
            //       }`
            // }
            // />
            <img
              src={
                thumbnailOrientation === "PORTRAIT"
                  ? `${process.env.REACT_APP_IMAGE_URL}${
                      data?.logo || data?.thumbnail
                    }`
                  : `${process.env.REACT_APP_IMAGE_URL}${
                      data?.logo_thumb || data?.thumbnail_350_200
                    }`
              }
              onError={(e)=>replaceImage(e)}
              alt="ShowImage"
            />
          ) : (
            // <ImageWithFallback
            // src={
            //   thumbnailOrientation === "PORTRAIT"
            //     ? `${data?.logo || data?.thumbnail}`
            //     : `${data?.logo_thumb || data?.thumbnail_350_200}`
            // }
            // projectInfo={projectInfo}
            // />
            <img
              src={
                thumbnailOrientation === "PORTRAIT"
                  ? `${data?.logo || data?.thumbnail}`
                  : `${data?.logo_thumb || data?.thumbnail_350_200}`
              }
              onError={(e)=>replaceImage(e)}

              alt="ShowImage"
            />
          )} */}
          <img
            src={
              imagURL
            }
            key={data?.show_id}
            onError={(e) => replaceImage(e)}
            onLoad={(e) => afterImageLoad(e)}
            alt="ShowImage"
          />
        </div>
        {data?.type === "CONTINUE_WATCHING" && (
          <div className="continueWatchingBar">
            <div
              className="line"
              style={{
                width:
                  data?.watched_percentage <= 1
                    ? 1 + "%"
                    : data?.watched_percentage + "%",
              }}
            ></div>
          </div>
        )}
        <div className="metaData">
          {
            type !== "UPCOMING_EVENTS" && type !== "NEWS" &&
            <div className="buttons">
              <div className="left">
                <button className="play">

                  <PlayIcon />
                </button>
                {
                  data?.show_id &&
                  <div className="watchlist">

                    {
                      watchlistStatus === "added" ? (
                        <button className="addToList" onClick={(e) => removeFromWatchlist(e)}>
                          <span className="tooltip remove"><Translate textKey={'remove_from_mylist'} /></span>
                          <RemoveFromWatchListIcon />
                        </button>
                      ) : (
                        <button className="addToList" onClick={(e) => addToWatchlist(e)}>
                          <span className="tooltip"><Translate textKey={'add_to_mylist'} /></span>
                          <AddListIcon />
                        </button>

                      )
                    }
                  </div>
                }
              </div>

            {/* <div className="right">
              <button className="moreInfo" onClick={()=>showClickHandler(data?.vanity_url?data?.vanity_url:data?.show_id,data?.show_id)}>
                <span className="tooltip">Episodes & Info</span>
                <DownArrow/>
              </button>
            </div> */}
          </div>
          }
          {type === "CONTINUE_WATCHING" && (
            <div className="continueWatchingContainer">
              <div className="continueWatchingBarHover">
                <div
                  className="line"
                  style={{
                    width:
                      data?.watched_percentage <= 1
                        ? 1 + "%"
                        : data?.watched_percentage + "%",
                  }}
                ></div>
              </div>
              <div className="watchedInfo">
                <span className="watchedLength">{secondsToMinutes(data?.watched_duration)} of {secondsToMinutes(data?.video_duration_seconds)}m</span>
              </div>
            </div>
          )}
          <h1 className="title">{data?.show_name}</h1>
          <div className="extraInfo">
            {
              data?.duration_text&&
              <span
              className={`duration ${
                /^[A-Za-z0-9\s]+$/.test(data.duration_text) ? "ltr" : ""
              }`}
            >
              {data.duration_text}
            </span>
            }
            {
              data?.rating&&
            <span className="rating">{data?.rating}</span>
            }
            {
              data?.year&&
            <span className="year">{data?.year}</span>
            }

          </div>
          <div className="categories">
            {/* {
              data?.category_names?.slice(0,3)?.map((item,index)=>(
                <div className="item">
                <span className="dot"></span>
                <span className="category">{item}</span>
                </div>
              ))
            } */}
          </div>
        </div>
      </div>
      {/* <div className={cardHover?"metaData active":"hide metaData"}>
      <div className="imageContainer2">
        {imageUrl ? (
          <img
            src={
              thumbnailOrientation === "PORTRAIT"
                ? `${process.env.REACT_APP_IMAGE_URL}${
                    data?.logo || data?.thumbnail
                  }`
                : `${process.env.REACT_APP_IMAGE_URL}${
                    data?.logo_thumb || data?.thumbnail_350_200
                  }`
            }
            alt="ShowImage"
          />
        ) : (
          <img
            src={
              thumbnailOrientation === "PORTRAIT"
                ? `${data?.logo || data?.thumbnail}`
                : `${data?.logo_thumb || data?.thumbnail_350_200}`
            }
            alt="ShowImage"
          />
        )}
        {type === "CONTINUE_WATCHING" && (
          <div className="continueWatchingBar">
            <div
              className="line"
              style={{
                width:
                  data?.watched_percentage <= 1
                    ? 1 + "%"
                    : data?.watched_percentage + "%",
              }}
            ></div>
          </div>
        )}

        
      </div>
      <div className="datas">
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni officiis rerum dignissimos dolorem quia accusamus cum, alias culpa. Eaque inventore quaerat exercitationem deserunt vero atque libero, corrupti quidem iusto dolore.</p>
      </div>

      

  </div> */}
    </div>
  );
};

export default ShowCard;
