import React, { useState } from 'react'
import ShowCard from '../../../Components/ShowCard/ShowCard';
import MagazineCard from '../../../Components/MagazineCard/MagazineCard';
const SwiperContainer = ({data,type,index,showCount,categoryData,thumbnailOrientation}) => {
 
  return (
    
    <div className={showCount>17?"show visibleShowAllButton":"show"}>
     
     {data?.type==="MAGAZINE" ?
      (
        <MagazineCard
        data={data}
        url={data.url}
        metaData={true}
        cardHover={true}
        />
      ) : data?.type === "SHORTS" ? (
        <ShowCard
        data={data}
        season={false}
        metaData={true}
        type={data?.type}
        vanityUrl={data?.vanity_url}
        thumbnailOrientation={thumbnailOrientation}
        />
      ) : (
        <ShowCard
        data={data}
        season={false}
        metaData={true}
        type={type}
        index={index}
        showCount={showCount}
        categoryData={categoryData}
        thumbnailOrientation={thumbnailOrientation}
      />
      )
      
     
      
    }
  </div>
  )
}

export default SwiperContainer