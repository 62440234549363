import React, { useEffect, useState } from "react";
import { imageUrlCheck } from "../../../utils/utils";
import { useSelector } from "react-redux";
import Translate from "../../../Components/MultiLanguage/Translate";
const CastAndCrew = ({ data ,type }) => {
  
  const [datas, setDatas] = useState([]);
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const thumbnailOrientation = projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION;
  useEffect(() => {
    if (data) {
      setDatas(data);
    }
  }, []);
  const replaceImage=(error)=>{
    error.target.src= thumbnailOrientation==='PORTRAIT'? projectInfo?.projectConfig?.config?.PLACEHOLDER_IMAGE_PORTRAIT : projectInfo?.projectConfig?.config?.PLACEHOLDER_IMAGE
  }

  return (
    <div className="castAndCrew" >
      <h1 className="heading"> <Translate textKey={type === 'Cast' ? "cast" : "crew"} /></h1>
      <ul className="lists">
        {datas?.map((item, index) => (
          <li className="list" key={index} onClick={() => item?.imdb_link && window.open(item?.imdb_link)}>
            {
                item?.image ? (
                  <div className="imageContainer">
                    {
                      imageUrlCheck(item?.image) === true ? (
                        <img
                          src={item?.image}
                          alt="Image"
                          onError={(e) => replaceImage(e)} 
                        />
                      ) : (
                        <img
                          src={`${process.env.REACT_APP_IMAGE_URL + item?.image}`}
                          alt="Image"
                          onError={(e) => replaceImage(e)}
                        />
                      )
                    }
                  </div>
                ) : (
                  <div className="imageContainer">
                    <img
                      src={projectInfo?.projectConfig?.config?.PLACEHOLDER_IMAGE} 
                      alt="Placeholder"
                    />
                  </div>
                )
              }

            <div className="info">
              <h1 className="name">{item?.name}</h1>
              <p className="role">{item?.role}</p>
            </div>
          </li>
        ))}
        
      </ul>
    </div>
  );
};

export default CastAndCrew;
