import React, { useEffect, useState, useRef } from "react";
import ShowCard from "../../../Components/ShowCard/ShowCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ReactComponent as RightArrow } from "../../../assets/Icons/pageDownArrow.svg";
import SwiperContainer from "./SwiperContainer";
import {useWindowWidth} from '@react-hook/window-size'
import { useTranslation } from 'react-i18next';
import { rowItemCount, translateText } from "../../../utils/utils";

const Categories = ({ data,type,lastChild }) => {
  const { t } = useTranslation();
  const languageModal = useSelector((state) => state?.languageModal?.value);
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const isRTL = useSelector((state) => state?.rtl?.isRTL);
  const isCardHover = useSelector((state) => state?.overlay?.value);
  const navigate = useNavigate();
  const [showSlider, setShowSlider] = useState(false);
  const [cardHover,setCardHover] = useState(false)
  const [rowItemsClass,setRowItemsClass] = useState("")
  const swiperRef = useRef(null);
  const [isLTR, setIsLTR] = useState(false);
  const [rowItemCountNumber, setRowItemCountNumber] = useState(6);

  const windowSize = useWindowWidth()
  const thumbnailOrientation = data?.thumbnail_orientation ? data?.thumbnail_orientation : projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION;

  useEffect(() => {
    setTimeout(() => setShowSlider(true), 500);
  }, []);

  // useEffect(() => {
  //   if(windowSize>980){
  //     if(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT){
  //       setRowItemsClass(rowItemCount(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT))
  //     }else{
  //       setRowItemsClass("rowCount6")
  //     }
  //   }else{
  //     setRowItemsClass("")
  //   }
  // }, [projectInfo,windowSize]);

useEffect(() => {
  if (windowSize > 980) {
    const newClass = projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT
      ? rowItemCount(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT)
      : "rowCount6"; 
    setRowItemsClass(newClass);
    
    const newCount = parseInt(newClass.replace(/\D/g, ''), 10);
    // if (data?.type === "FASTCHANNELS") {
    //   setRowItemCountNumber(newCount > 9 ? 9 : newCount); 
    // } else {
      setRowItemCountNumber(newCount);
    // }
  } else {
    if (windowSize > 767) {
      setRowItemsClass("rowCount3");
      // setRowItemCountNumber(data?.type === "FASTCHANNELS" ? 4 : 3);
      setRowItemCountNumber(3);
    } else if (windowSize > 479) {
      setRowItemsClass("rowCount2");
      // setRowItemCountNumber(data?.type === "FASTCHANNELS" ? 3 : 2);
      setRowItemCountNumber(2);
    } else if (windowSize > 319) {
      setRowItemsClass("rowCount1");
      setRowItemCountNumber(1); 
    }
  }
}, [projectInfo, windowSize, data?.type]);

  const handleSwiperInit = (swiperInstance) => {
    swiperRef.current = swiperInstance;
  };

  useEffect(() => {
    setIsLTR(data?.shows?.length > rowItemCountNumber);
  }, [data?.shows?.length, rowItemCountNumber]);

  const displayedShows = ( isRTL && isLTR ) ? [...data?.shows?.slice(0,17)].reverse() : data?.shows?.slice(0,17);

  const swiperHandler=displayedShows?.map((item, index) => {
    return(

    <SwiperSlide 
      key={index} 
      onMouseEnter={()=>setCardHover(true)} 
      onMouseLeave={()=>setCardHover(false)}
      className={`hover ${isRTL ? "rtl" : "ltr"} ${thumbnailOrientation === "PORTRAIT"?`portrait`:`landscape`}`}
      >
      <SwiperContainer categoryData={data} data={item} showCount={data?.shows?.length} type={type} index={index} thumbnailOrientation={thumbnailOrientation} />
    </SwiperSlide>
    )
})

  return (
    <div className={cardHover 
  ? `categoriesContainer hideArrow ${lastChild && `lastChild`} ${thumbnailOrientation === "PORTRAIT" ? `portrait` : `landscape`} ${rowItemsClass} ${(isRTL && isLTR) ? "ltr" : isRTL ? "rtl" : ""}` 
  : `categoriesContainer ${thumbnailOrientation === "PORTRAIT" ? `portrait` : `landscape`} ${lastChild && `lastChild`} ${rowItemsClass} ${(isRTL && isLTR) ? "ltr" : isRTL ? "rtl" : ""}`}>
      <div
        className={showSlider ? "showSlider itemsContainer" : "itemsContainer"}
      >
        <div
          className="categoryNameContainer"
          onClick={() =>
            navigate(`/category/${data?.key}?career=${data?.key}`, {
              state: { careers: data?.key, type: data?.type },
            })
          }
        >
          <div className="content">

            <h1>{data?.category_name}</h1>
            <div className="exploreAll">
              <div className="seeAll">{translateText(languageModal, t, 'explore_all')}</div>
              <div className="rightArrow">
                <RightArrow className={`${isRTL && isLTR ? "ltr" : isRTL ? "rtl" : ""}`}/>
              </div>
            </div>
          </div>
        </div>
        <Swiper
          key={`${isRTL}-${isLTR}`}
          modules={[Navigation]}
          spaceBetween={1.5}
          slidesPerView={6}
          slidesPerGroup={6}
          navigation={true}
          watchSlidesProgress
          speed={1000}
          loop={false}
          onSwiper={handleSwiperInit} 
          initialSlide={( isRTL && isLTR ) ? displayedShows.length - 1 : 0}
          breakpoints={{
            320: {
              slidesPerView: thumbnailOrientation == "PORTRAIT" ? 2 : 1,
              spaceBetween: 2,
              slidesPerGroup:thumbnailOrientation == "PORTRAIT" ? 2 : 1,
            },
            360: {
              slidesPerView: thumbnailOrientation == "PORTRAIT" ? 3 : 3,
              spaceBetween: 2,
              slidesPerGroup:thumbnailOrientation == "PORTRAIT" ? 3 : 3,
            },
            480: {
              slidesPerView: thumbnailOrientation == "PORTRAIT" ? 3 : 3,
              spaceBetween: 5,
              slidesPerGroup:thumbnailOrientation == "PORTRAIT" ? 3 : 3,
            },
            640: {
              slidesPerView: thumbnailOrientation == "PORTRAIT" ? 4 : 3,
              spaceBetween: 5,
              slidesPerGroup:thumbnailOrientation == "PORTRAIT" ? 4 : 3,
            },
            768: {
              slidesPerView: thumbnailOrientation == "PORTRAIT" ? 4 : 4,
              spaceBetween: 5,
              slidesPerGroup:thumbnailOrientation == "PORTRAIT" ? 4 : 4,
            },
            980: data?.type === "SHORTS" ? {
              slidesPerView: 4,
              spaceBetween: 10,
            } : {
              slidesPerView: projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT?Number(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT):4,
              spaceBetween: 5,
              slidesPerGroup:projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT?Number(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT):4

            },
            981: data?.type === "SHORTS" ? {
              slidesPerView: 4,
              spaceBetween: 10,
            } : {
              slidesPerView: thumbnailOrientation == "PORTRAIT" ? 6 : 5,
              spaceBetween: 5,
              slidesPerGroup:thumbnailOrientation == "PORTRAIT" ? 6 : 5
            },
            1200: data?.type === "SHORTS" ? {
              slidesPerView: 6,
              spaceBetween: 10,
            } : {
             
              slidesPerView: projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT?Number(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT):7,
              spaceBetween: 5,
              slidesPerGroup:projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT?Number(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT):7
            },
            2200:{
              slidesPerView: projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT?Number(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT):8,
              spaceBetween: 5,
              slidesPerGroup:projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT?Number(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT):8

            }
          }}
        >
          {swiperHandler}
          {/* <SwiperSlide>
            <div className="showMoreCard">
              <h2>Show All ({data && data?.shows?.length})</h2>
            </div>
          </SwiperSlide> */}
        </Swiper>
      </div>
    </div>
  );
};

export default Categories;
